
const constants = function(){

    const list = {

        ADD_USER:"ADD_USER",
        SET_LOADING:"SET_LOADING",
        CLEAR_LOADING:"CLEAR_LOADING",

    }

    return list;

}

export default constants;